import React from 'react';
import './imgloop.css'

import imgloop from './imgloop';

// import woman image
import HeroBanner from '../assets/img/logo.png';
import verticalBanner from '../assets/img/radioNorteChicoVertical.png'

const Hero = () => {
  return (
    <section
      id='home'
      className='fondo lg:h-[85vh] flex items-center bg-primary lg:bg-cover lg:bg-center lg:bg-no-repeat py-32 lg:py-0 overflow-hidden'
    >
      <img className=' mt-1 w-[9%] md:w-[11%] xl:w-[9%]   absolute ' src={verticalBanner}/>
      <div className='container mx-auto h-full '>
        <div className='flex items-center h-full pt-8'>
        
          <div className='flex-1 flex flex-col items-center lg:items-start'>
          
            <p className='lg:ml-[10%] xl:ml-[5%]   text-lg text-accent text-md mb-[22px]'>
              Tu radio 👋
            </p>
            
            <h1 className='lg:ml-[10%] xl:ml-[5%] text-4xl leading-[44px] md:text-5xl md:leading-tight lg:text-7xl lg:leading-[1.2] font-bold md:tracking-[-2px]'>
                <br /> 
            </h1>
            <p className='lg:ml-[10%] xl:ml-[5%] pt-4 pb-8 md:pt-6 md:pb-12 max-w-[480px] text-lg text-center lg:text-left'>
              Somos una radio distinta
            </p>
            
            <button className='text-black font-semibold btn btn-md bg-[#B0B0B0] hover:bg-[#2c361a]-hover md:btn-lg transition-all rounded-lg'>
              Saber más
            </button>
          </div>
          <div className='hidden lg:flex flex-1 justify-end items-end h-full'>
            <img className='w-screen' src={HeroBanner} alt='' />
            <img className='banner-animated right-7 absolute w-60' src={HeroBanner}/>
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
