//  icons
import {
  FiYoutube,
  FiInstagram,
  FiGithub,
  FiDribbble,
  FiLayout,
  FiSettings,
  FiPenTool,
  FiTag,
  FiMail,
  FiMapPin,
  
} from 'react-icons/fi';


  import { BsYoutube } from "react-icons/bs";


// companies icons
import FreelancerBrandIcon from './assets/img/brands/freelancer.png';
import UpworkBrandIcon from './assets/img/brands/upwork.png';
import FiverBrandIcon from './assets/img/brands/fiverr.png';
import BehanceBrandIcon from './assets/img/brands/behance.png';
import DribbbleBrandIcon from './assets/img/brands/dribbble.png';

// projects images
import Project1 from './assets/img/projects/p1.webp';
import Project2 from './assets/img/projects/p2.webp';
import Project3 from './assets/img/projects/p3.webp';
import Project4 from './assets/img/projects/p4.webp';
import Project5 from './assets/img/projects/p5.webp';
import Project6 from './assets/img/projects/p6.webp';

// skills images
import SkillImg1 from './assets/img/skills/html5.png';
import SkillImg2 from './assets/img/skills/css3.png';
import SkillImg3 from './assets/img/skills/js.png';
import SkillImg4 from './assets/img/skills/reactjs.png';
import SkillImg5 from './assets/img/skills/nextjs.png';
import SkillImg6 from './assets/img/skills/nodejs.png';
import SkillImg7 from './assets/img/skills/git.png';
import SkillImg8 from './assets/img/skills/figma.png';

// testimonial images

import TestiImage4 from './assets/img/testimonials/publicidad.png';

// navigation
export const navigation = [
  {
    name: 'Inicio',
    href: '/Inicio',
  },
  {
    name: 'Radio Online',
    href: 'radio',
  },
  {
    name: 'Publicidad',
    href: 'publicidad',
  },
  {
    name: 'Nosotros',
    href: 'nosotros',
  },
  {
    name: 'Noticias',
    href: 'noticias',
  },
  {
    name: 'Contacto',
    href: 'contacto',
  },
];

// social
export const social = [
  {
    src: "https://cdn-icons-png.flaticon.com/512/174/174883.png",
    href: 'https://www.youtube.com/',
  },
  {
    src: "https://cdn-icons-png.flaticon.com/512/174/174855.png",
    href: '',
  },
  {
    src: "https://cdn-icons-png.flaticon.com/512/174/174848.png",
    href: '',
  },
  
];

// companies
export const brands = [
  {
    img: FreelancerBrandIcon,
    href: '',
  },
  {
    img: UpworkBrandIcon,
    href: '',
  },
  {
    img: FiverBrandIcon,
    href: '',
  },
  {
    img: BehanceBrandIcon,
    href: '',
  },
  {
    img: DribbbleBrandIcon,
    href: '',
  },
];

// projects
export const projectsData = [
  {
    id: '1',
    image: "https://f.rpp-noticias.io/2022/07/07/ves0707-2022-07-07-11-37-33-31721-1282457mp4_1282458.png",
    name: 'En plena cuarta ola, aumentó la cantidad de personas que acuden a realizarse pruebas de descarte [VIDEO]',
    category: 'Nacional',
    desc:"En plena cuarta ola de la pandemia, ha aumentado la cantidad de personas que acuden a los centros de toma de pruebas de descarte de la COVID-19, según constató un equipo de RPP Noticias en un recorrido."
  },
  {
    id: '2',
    image: "https://e.rpp-noticias.io/normal/2022/07/07/100010_1282765.jpg",
    name: 'Consejo de la Prensa Peruana pide al Congreso archivar el proyecto de ley mordaza',
    category: 'Nacional',
    desc:"El Consejo de la Prensa Peruana indicó que, en caso sea aprobada la iniciativa, la población no podrá estar al tanto de las investigaciones por casos de corrupción, como 'Lava Jato', 'Los cuellos blancos del puerto' y, últimamente, los presuntos delitos del presidente de la República, Pedro Castillo, y su entorno."
  },
  {
    id: '3',
    image: "https://e.rpp-noticias.io/normal/2022/07/07/082808_1282690.jpg",
    name: 'Corte Suprema rechazó pedido de Daniel Salaverry para archivar presuntos delitos que le atribuye la Fiscalía en investigación',
    category: 'Nacional',
    desc:"Corte Suprema rechazó pedido de Daniel Salaverry para archivar presuntos delitos que le atribuye la Fiscalía en investigación"
  },
  {
    id: '4',
    image: "https://e.rpp-noticias.io/normal/2022/07/07/453545_1282821.jpg",
    name: 'Ex primer ministro de Japón Shinzo Abe sufrió paro cardiorrespiratorio tras recibir disparos y presunto atacante ya fue detenido',
    category: 'Internacional',
    desc:"Se cree que el exmandatario de Japón recibió disparos de escopeta por la espalda mientras ofrecía un discurso de campaña en la calle cerca de una estación de tren en la ciudad de Nara, según informó la Policía local, que ha detenido a un hombre como supuesto autor del ataque."
  },
  {
    id: '5',
    image: "https://e.rpp-noticias.io/normal/2022/07/07/1282630000-32dj2pvjpg.jpg",
    name: 'Vladimir Putin desafía a Occidente y advierte que ofensiva rusa en Ucrania aún no empezó "en serio"',
    category: 'Internacional',
    desc:`"Oímos actualmente que [los occidentales] quieren derrotarnos en un campo de batalla", comentó el presidente ruso, Vladimir Putin, en tono desafiante. "¿Qué decirles? ¡Que lo intenten!", declaró, acusando a "todo Occidente" de haber desatado una "guerra" en Ucrania.`
    
  },
  {
    id: '6',
    image: "https://e.rpp-noticias.io/normal/2022/07/07/1282759151615-1210777jpg.jpg",
    name: 'Guatemala: uso de mascarilla volverá a ser obligatorio ante incrementos de casos de la COVID-19',
    category: 'Internacional',
    desc:"El ministro de Salud guatemalteco, Francisco Coma, anunció la medida en una rueda de prensa, después de que la cartera que dirige removiera en mayo pasado la obligación de usar mascarilla para toda la población."
  },
  {
    id: '7',
    image: "https://images11.eitb.eus/multimedia/images/2022/07/03/2958232/frame_1656854016_foto960.jpg",
    name: 'El 90 % de los casos de covid actuales son de las subvariantes nuevas más contagiosas',
    category: 'ciencia',
    desc:"Las vacunas no están siendo tan eficaces ante estas nuevas variantes. Se pide precaución sobre todo para las personas inmunodeprimidas, y ponerse la mascarillas en aglomeraciones o sitios cerrados."
  },
  {
    id: '8',
    image: "https://images11.eitb.eus/multimedia/images/2022/06/13/2949078/20220613183511_izarrakgaia-esa-estrellas_foto610x342.jpg",
    name: 'El mapa más detallado de la Vía Láctea incluye terremotos estelares y estrellas desconocidas',
    category: 'ciencia',
    desc:"La Agencia Espacial Europea (ESA) lanzó en 2013 la Misión Gaia, con el objetivo de crear el mapa multidimensional más preciso y completo de la Vía Láctea."
  },
  {
    id: '9',
    image: "https://images11.eitb.eus/multimedia/images/2022/06/06/2945614/frame_1654513853_foto960.jpg",
    name: 'Los próximos meses llegarán nuevas vacunas reformuladas contra la covid-19',
    category: 'ciencia',
    desc:"Fernando Moraga-Llop, vicepresidente de la Asociación Española de Vacunología, asegura que estas nuevas vacunas que llegarán los meses de verano y otoño tendrán muy buena respuesta contra las últimas cepas del virus."
  },
  
];

// projects
export const projectsNav = [
  {
    name: 'Todo',
  },
  {
    name: 'Nacional',
  },
  {
    name: 'Internacional',
  },
  {
    name: 'Ciencia',
  },
];

// skill
export const skills = [
  {
    image: SkillImg1,
  },
  {
    image: SkillImg2,
  },
  {
    image: SkillImg3,
  },
  {
    image: SkillImg4,
  },
  {
    image: SkillImg5,
  },
  {
    image: SkillImg6,
  },
  {
    image: SkillImg7,
  },
  {
    image: SkillImg8,
  },
];

// services
export const services = [
  {
    icon: <FiLayout />,
    name: 'Web Design',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio perferendis volm quibusdam ullam qui dolore.',
  },
  {
    icon: <FiSettings />,
    name: 'Web Development',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio perferendis volm quibusdam ullam qui dolore.',
  },
  {
    icon: <FiPenTool />,
    name: 'Branding',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio perferendis volm quibusdam ullam qui dolore.',
  },
  {
    icon: <FiTag />,
    name: 'SEO',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio perferendis volm quibusdam ullam qui dolore.',
  },
];

// testimonials
export const testimonials = [
  {
    img: "https://firebasestorage.googleapis.com/v0/b/waras-e7a3d.appspot.com/o/slider%20norte%20chico%2FCaptura%20de%20pantalla%202022-07-08%20091205%20(FILEminimizer).jpg?alt=media&token=75553e6a-c80f-4eab-ae0c-d2b490da2c92",
    titulo:
      'Centro Latino',
    rubro: 'Restaurant',
    descripcion: 'Sandwich Shop - Restaurant Cafeteria',
    telefono: "201-995-0001",
    direccion:"6500 Park AVE. West New York, N.J. 07093"
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/waras-e7a3d.appspot.com/o/slider%20norte%20chico%2Fhmpage-image.jpg?alt=media&token=0ee30f47-f23a-4921-b1f4-7e17afceb31d",
    titulo:
      'Stone Cortez LLC',
    rubro: '',
    descripcion: 'Granite, Marble, Quartz, Fabrication, Installation, Kitchen Counter, Vanity Tops, Fire Places, All Stone Work',
    telefono:'201-562-6576'
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/waras-e7a3d.appspot.com/o/slider%20norte%20chico%2F750x750bb.jpeg?alt=media&token=74b183e6-379e-466b-bb80-8666702e377e",
    titulo:
      'Rapido Car Service',
    rubro: 'Car Service',
    descripcion: '',
    telefono:'201-348-8484',
    direccion:'4901 Park Avenue, Union City, NJ 07087'
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/waras-e7a3d.appspot.com/o/slider%20norte%20chico%2Funnamed.png?alt=media&token=6f9eb077-293d-47b6-bd44-0f9b8e2d8bf4",
    titulo:
      'Viva Taxi',
    rubro: '201-558-0909',
    descripcion: '2911 Summit Ave, Union City NJ 07087',
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/waras-e7a3d.appspot.com/o/slider%20norte%20chico%2FCaptura%20de%20pantalla%202022-07-08%20094153%20(FILEminimizer).jpg?alt=media&token=6205769a-0fbd-4ad8-8f6d-72b20bd94880",
    titulo:
      'Restaurant Rinconcito de Paramonga',
    rubro: 'Peruvian Cuisine',
    descripcion: '731-32 Union City NJ',
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/waras-e7a3d.appspot.com/o/slider%20norte%20chico%2F21762192_125898001398522_2311357613989426104_n.png?alt=media&token=29f1d62a-d0ee-4210-9d1d-82c104a096ef",
    titulo:
      'Cecorle Import and Export',
    rubro: 'Compra y venta de instrumentos musicales nuevos y usados',
    descripcion: '1708 Palisade Ave, Union City NJ 07087',
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/waras-e7a3d.appspot.com/o/slider%20norte%20chico%2F72769087_595205317721652_704689294438039552_n.jpg?alt=media&token=c9561af5-55b6-40b7-b85f-b3c925cd590d",
    titulo:
      'Ark Cash',
    rubro: 'Envio de dinero a todos los paises, Envio de encomiendas a centro américa, Colombia, Mexico y Ecuador',
    descripcion: '1002 Summit Ave, Union City',
  },
  
];

// contact
export const contact = [
  {
    icon: <FiMail />,
    title: 'Tienes alguna pregunta?',
    subtitle: 'Estamos aqui para ayudarte',
    description: 'Enviar email a soporte@radionortechico.com',
  },
  {
    icon: <FiMapPin />,
    title: '',
    subtitle: '',
    description: 'Contacto alrededor del mundo',
  },
];
