import React from 'react'

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import './custom.css'



const Reproductor = () => {
  return (
    <section id='radio' className='section bg-secondary'>
        <figure>
    <h2 className='text-3xl font-bold mb-4 '>Radio Online:</h2>
    
    
</figure>
<AudioPlayer
    autoPlay={true}
    customAdditionalControls={[]}
    showJumpControls={[]}    
    layout='stacked-reverse'
    src="https://radio.sistemasandinos.org/7374/stream"
    //onPlay={e => console.log("onPlay")}
    // other props here
  />

</section>
  )
}

export default Reproductor