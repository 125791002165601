import React from 'react';

// import components
import Projects from './Projects';

const Portfolio = () => {
  return (
    <section id='noticias' className='section bg-primary min-h-[1400px]'>
      <div className='container mx-auto'>
        <div className='flex flex-col items-center text-center'>
          <h2 className='section-title before:content-none relative before:absolute before:opacity-40 before:-top-[2rem] before:-left-3/4 before:hidden before:lg:block'>
            MISION
          </h2>
          <p className='subtitle'>
          Nuestra mision, es unir a toda comunidad peruana alrededor del mundo; recordando que tenemos una
historia,cultura,musica. En nuestra radio encontraran una gran variedad de musica, peruana.
          </p>
        </div>
        <h2 className='section-title before:content-none relative before:absolute before:opacity-40 before:-top-[2rem] before:-left-3/4 before:hidden before:lg:block'>Noticias</h2>
        <Projects />
      </div>
    </section>
  );
};

export default Portfolio;
